
export default {
  name: 'delete-dialog',
  components: {},
  props: ["title","isDelete"],
  data () {
    return {
      deleteDialog:false,
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    confirmDelete(){
      this.deleteDialog = true;
    },
    CloseDialog(){
      this.deleteDialog = false;
    },
    DeleteItem(){
      this.$emit("DeleteItem")
    }
  }
}


