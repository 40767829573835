import rules from "../../../utils/rules";
import appsheettoolsService from "../../../api/appsheettools.service";
import ViewAuditItem from "../../secured/viewaudititem";
import XLSX from "xlsx";
import { mapGetters } from "vuex";
import moment from "moment";
import pageheader from ".././../common/pageheader";
import utils from "../../../utils/utils";
import EventBus from "../../../utils/EventBus";

export default {
  name: "U360Lite",
  components: { XLSX, ViewAuditItem, pageheader },
  props: [],
  data() {
    return {
      input: {},
      tagsList: [
        { name: "Favorite", value: "Favorite" },
        { name: "Notes", value: "Notes" }
      ],
      tagValue: "",
      customerName: "",
      indexValue: "",
      auditArrayItem: {},
      newValues: [],
      isChip: false,
      viewfavicon: false,
      viewFinanceIcon: false,
      loadfav: false,
      loadFinance: false,
      iconLoader: true,
      selected: false,
      isFavourite: false,
      exceptionsList: [],
      chips: [],
      selectedChips: [],
      currentInput: "",
      panel: 1,
      counts: {},
      CountArray: [],
      auditList: [],
      valid: true,
      searchAudit: "",
      CustomersList: [],
      SelectedCustomer: "",
      selectedCustomerId: "",
      menu1: false,
      menu2: false,
      showselect:false,
      fromDate: "",
      fromDateFormatted: "",
      toDate: "",
      toDateFormatted: "",
      isFromShowMask: true,
      isToShowMask: true,
      rules: rules,
      loading: false,
      isLoading: false,
      isAudit: false,
      tablesearch: "",
      GetExcel: [],
      dialog: false,
      ExcelResponse: [],
      model: [],
      search: null,
      items: [],

      headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "sno",
          width: "60px",
        },
        {
          text: "Customer  Name",
          value: "customerName",
          width: "140px",
          align: "left"
        },
        {
          text: "Branch Name",
          value: "branchName",
          width: "90px",
          align: "left"
        },
        { text: "TU Serial", value: "tuSerial", width: "100px", align: "left" },
        {
          text: "Service Type",
          value: "serviceType",
          width: "90px",
          align: 'center',
          class:`main-header table-header-padding`
        },
        {
          text: "Machine Id",
          value: "machineId",
          width: "90px",
          align: "left"
        },
        {
          text: "Machine Year",
          value: "machineYear",
          width: "90px",
          align: 'center',
          class:`main-header table-header-padding`
        },
        {
          text: "Machine Serial",
          value: "machineSerial",
          width: "120px",
          align: "left"
        },
        {
          text: "Manufacturer Name",
          value: "manufactureName",
          width: "90px",
          align: 'center',
          class:`main-header table-header-padding`
        },
        { text: "Model", value: "model", width: "90px", align: "left" },
        { text: 'Machine Status', value: 'machineStatus', width: '120px', align: 'left' },
        {
          text: "Machine Description",
          value: "machineDescription",
          width: "120px",
          align: "left"
        },
        {
          text: "Machine Type",
          value: "machineType",
          width: "90px",
          align: "left"
        },

        {
          text: "Service Date",
          value: "serviceDate",
          width: "130px",
          align: "left"
        },
        {
          text: "Engine Hours",
          value: "engineHours",
          width: "100px",
          align: "left"
        },
        {
          text: "Vendor Name",
          value: "vendorName",
          width: "100px",
          align: "left"
        },
        {
          text: "Source",
          value: "source",
          width: "100px",
          align: "left"
        },
        {
          text: "Reference Field",
          value: "referenceField",
          width: "100px",
          align: "left"
        },
        {
          text: "Installation City",
          value: "installationCity",
          width: "100px",
          align: "left"
        },
        {
          text: "Installation State",
          value: "installationState",
          width: "100px",
          align: "left"
        },
      ],
      options: {},
      serviceID: null,
      showViewItemDialog: false,
      viewAuditKey: 0,
      selectedStatus: "Completed",
      serviceTypes: [],
      selectedServiceType: [],
      areFiltersLoading: false,
      exportOptions: ["SUMMARY", "REPORT DATA"],
      selectedExportOption: null,
      exportingExcel: false,
      parentcompaniesList: [],
      companySelected: [],
      storedCRIds: [],
    };
  },
  computed: {
    ...mapGetters([
      "auditFinanceCustomer",
      "auditFinanceFromDate",
      "auditFinanceToDate",
      "roles",
      "reportId",
      "appsheetId",
      "vendorIds",
      "vendorName",
      "companyName",
      "toolIds",
      "loggedInReferenceId",
    ]),
    likesAllCustomers(){
      return this.companySelected.length === this.parentcompaniesList.length;
    },
    likesSomeCustomers() {
      return this.companySelected.length > 0 && !this.likesAllCustomers;
    },
    iconCustomer() {
      if (this.likesAllCustomers) return "mdi-checkbox-marked";
      if (this.likesSomeCustomers) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllServices() {
      return this.selectedServiceType.length === this.serviceTypes.length;
    },
    likesSomeServices() {
      return this.selectedServiceType.length > 0 && !this.likesAllServices;
    },
    icon() {
      // if (this.likesAllServices) return "mdi-close-box";
      if (this.likesAllServices) return "mdi-checkbox-marked";
      if (this.likesSomeServices) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  mounted() {
  
    this.defaultSearchOnPageLoad(false);
    this.input = utils.getInputContent("auditTool", this.roles);
    
    EventBus.$on('call-audit-tool', () => {
      this.defaultSearchOnPageLoad(true);
    });
  },
  beforeDestroy() {
    EventBus.$off("call-audit-tool");
   },
  filters: {
    formatDate: function(value) {
      if (value) {
        const format = "MM-DD-YYYY";
        return moment(value).format(format);
      }
    },
    formatDates: function(value) {
      if (value) {
        const format = "MM/DD/YYYY";
        return moment(value).format(format);
      }
    },
    formatDateTimeLocal: function (value) {
      if (value) {
        const format = 'MM/DD/YYYY hh:mm A'
        return moment.utc(value)
        .local().format(format)
      }
    },
  },
  watch: {
    selectedChips(val, prev) {
      if (val.length === prev.length) return;
      this.selectedChips = val.map(v => {
        // v= v.trim();
        if (typeof v === "string" && v != "") {
          v = {
            name: v
          };

          // this.items.push(v);
        }
        return v;
      });
      // return val;
    },
    fromDate (val) {
      this.fromDateFormatted = this.formatDateNew(val)
      this.isFromShowMask = false;
      setTimeout(() => {
        this.isFromShowMask = true;
      }, 1);
      this.onFromDateChange();
    },
    toDate (val) {
      this.toDateFormatted = this.formatDateNew(val)
      this.isToShowMask = false;
      setTimeout(() => {
        this.isToShowMask = true;
      }, 1);
      this.onToDateChange();
    },
  },
  methods: {
    formatDateNew (date) {
      if (!date) return ""

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date, isFromDate) {
      if (!date) return ""

      const [month, day, year] = date.split('/')
      if (!month || month<1 || month>12 || !day || day<1 || day>31 || !year || year<2000 || year>2100) {
        if (isFromDate) {
          this.fromDate = "";
          this.fromDateFormatted = "";
          return ""
        } else {
          this.toDate = "";
          this.toDateFormatted = "";
          return ""
        }
      } else {
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
    },
    async CompanyList() {
      this.parentcompaniesList = [];
      this.companySelected = [];
      try {
        let response = await appsheettoolsService.getUserCustomers();
         if (response && response.data) {
           this.parentcompaniesList = response.data;
         }
           
        this.areFiltersLoading = false;
       
       } catch (error) {
        this.areFiltersLoading = false;
       }
     }, 
     removeCustomer(){
      this.companySelected = [];
     },
    async OpenFinance(item, index) {
      item.loadFinance = true;
      let userId = this.$store.getters.userId;
      let payload = {
        serviceId: item.serviceId,
        type: 2,
        isFlag: !item.hasFinance,
        createdBy: userId
      };
      this.auditList[index - 1]["hasFinance"] = !this.auditList[index - 1][
        "hasFinance"
      ];
      try {
        let finance = await appsheettoolsService.createFavourite(payload);
        console.log(finance);
        item.loadFinance = false;
      } catch (error) {
        item.loadFinance = false;
        console.log(error);
      }
    },
    async OpenFavourites(item, index) {
      item.loadfav = true;
      this.iconLoader = false;
      let userId = this.$store.getters.userId;
      let payload = {
        serviceId: item.serviceId,
        type: 1,
        isFlag: !item.isFavorite,
        createdBy: userId
      };
      this.auditList[index - 1]["isFavorite"] = !this.auditList[index - 1][
        "isFavorite"
      ];
      try {
        let favourites = await appsheettoolsService.createFavourite(payload);
        console.log(favourites);
        item.loadfav = false;
      } catch (error) {
        item.loadfav = false;
        console.log(error);
      }
    },
    deleteChip(index) {
      this.newValues.splice(index, 1);
    },
    updateSearch() {
      let tempchips = [];
      tempchips = this.newValues.map(e => e.searchdata);
      this.searchAudit = tempchips.toString();
    },
    async loadCustomers() {
      try {
        this.areFiltersLoading = true;
        let response = await appsheettoolsService.getCutomersList();
        if (response) {
          this.CustomersList = response;
          this.GetServiceType();
        }
      } catch (err) {
        this.areFiltersLoading = false;
        console.log(err);
      }
    },
    async GetServiceType() {
      try {
        let response = await appsheettoolsService.getAuditServiceTypes();
        if (response) {
          this.serviceTypes = response;
          // this.serviceTypes.unshift("All");
          this.areFiltersLoading = false;
        }
      } catch (err) {
        this.areFiltersLoading = false;
        console.log(err);
      }
    },
    ClearTag() {
      if(this.tagValue){
      this.tagValue = "";
      }
    },
    ClearserviceType() {
      if(this.selectedServiceType){
      this.selectedServiceType = "";
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllServices) {
          this.selectedServiceType = [];
        } else {
          this.selectedServiceType = this.serviceTypes.slice();
        }
      });
    },
    toggleCustomer() {
      this.$nextTick(() => {
        if (this.likesAllCustomers) {
          this.companySelected = [];
        } else {
          this.companySelected = this.parentcompaniesList.slice();
        }
      });
    },
    ClearMachineIds() {
      if(this.searchAudit){
      this.searchAudit = "";
      }
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    ClearDates() {
      if(this.fromDate){
        this.fromDate = "";
        this.$store.dispatch("setFromDate", this.fromDate);
      }
      if(this.toDate){
      this.toDate = "";
      this.$store.dispatch("setToDate", this.toDate);
      }
    },
    ClearFilters() {
      this.loading = false;
      this.isLoading = false;
      // this.SelectedCustomer = "";
      this.selectedStatus = "Completed";
      this.selectedServiceType = "";
      this.fromDate = "";
      this.toDate = "";
      this.searchAudit = "";
      this.selectedExportOption = null;
      this.tagValue = "";
      // this.$store.dispatch("setCustomer", this.SelectedCustomer);
      this.$store.dispatch("setFromDate", this.fromDate);
      this.$store.dispatch("setToDate", this.toDate);
      this.companySelected = [];
    },
    OpenAuditDialog(item) {
      // console.log(item);
      this.serviceID = item.serviceId;
      this.indexValue = item.sno;
      // this.eachviewData = item;
      this.auditArrayItem = item;
      this.showViewItemDialog = true;
      this.viewAuditKey = this.viewAuditKey + 1;
      this.viewfavicon = item.isFavorite;
      this.viewFinanceIcon = item.viewFinanceIcon;
    },
    // onCustomerChange() {
    //   this.$store.dispatch("setCustomer", this.SelectedCustomer);
    // },
    onFromDateChange() {
      this.$store.dispatch("setFromDate", this.fromDate);
    },
    onToDateChange() {
      this.$store.dispatch("setToDate", this.toDate);
    },
    defaultSearchOnPageLoad(fromEvent) {
      this.CompanyList();
      this.GetServiceType();
      // this.loadCustomers();
      //this.GetServiceType();
      // this.SelectedCustomer = this.$store.getters.reportId
      //   ? this.$store.getters.reportId
      //   : "";
      // this.selectedCustomerId = this.$store.getters.reportId
      //   ? this.$store.getters.reportId
      //   : "";
        // this.SelectedCustomer = this.$store.getters.appsheetId
        // ? this.$store.getters.appsheetId
        // : "";
      this.fromDate = this.$store.getters.auditFinanceFromDate
        ? this.$store.getters.auditFinanceFromDate
        : "";
      this.toDate = this.$store.getters.auditFinanceToDate
        ? this.$store.getters.auditFinanceToDate
        : "";
      if ((!fromEvent && this.selectedCustomerId) || fromEvent) {
        let todayDate = new Date();
        let setDateString = new Date();
        let fromDate, toDate;
        if (!this.fromDate && !this.toDate) {
          todayDate.setDate(todayDate.getDate() - 14);
          fromDate = todayDate.toISOString().slice(0, 10);
          setDateString.setDate(setDateString.getDate() - 1);
          toDate = setDateString.toISOString().slice(0, 10);
          this.fromDate = fromDate;
          this.toDate = toDate;
          this.getAuditTools();
        } else {
          this.getAuditTools();
        }
      }
    },
    async getAuditTools() {
      this.auditList = [];
      this.ExcelResponse = [];
      this.exceptionsList = [];
      this.CountArray = [];
      this.serviceTypeCountArray = [];
      if (this.companySelected.length>0){
        if (this.$refs.form.validate()) {
          if (
            ((this.fromDate == ""  || this.fromDate == undefined || this.fromDate == null) && this.toDate) ||
            (this.fromDate && (this.toDate == '' || this.toDate == undefined || this.toDate == null))
          ) {
            this.$toast.error("Please enter both From date and To date. ");
            this.loading = false;
            this.isAudit = false;
            return;
          }
          // this.customerName = this.SelectedCustomer;
          if (
            this.searchAudit !== "" ||
            this.selectedCustomerId !== "" ||
            (this.fromDate && this.toDate) ||
            this.selectedStatus !== "" ||
            this.selectedServiceType !== "" ||
            this.tagValue !== ""
          ) {
            if (Date.parse(this.toDate) < Date.parse(this.fromDate)) {
              this.$toast.error(
                "To date must be greater than or equal to From date "
              );
              this.loading = false;
              this.isAudit = false;
              return;
            }
            this.$store.dispatch("setFromDate", this.fromDate);
            this.$store.dispatch("setToDate", this.toDate);
            this.fromDate = this.$store.getters.auditFinanceFromDate
              ? this.$store.getters.auditFinanceFromDate
              : "";
            this.toDate = this.$store.getters.auditFinanceToDate
              ? this.$store.getters.auditFinanceToDate
              : "";
            this.isAudit = true;
            this.loading = true;
            this.isLoading = true;
            let type = "";
            if (this.selectedServiceType.length === this.serviceTypes.length) {
              type = "";
            } else {
              type = this.selectedServiceType;
            }
            let searchdata = "";
            if (this.searchAudit != "" || this.searchAudit != " ") {
              searchdata = this.searchAudit.trim();
              // Getting ids with double quotes in array 
              let searchDataWithQuotes = searchdata.match(/"(.*?)"/g);
              let searchDataAfterRemovingQuotes = [];
              if (searchDataWithQuotes && searchDataWithQuotes.length>0) {
                searchDataWithQuotes.map((id) => {
                  // removing ids with double quotes from searchdata
                  searchdata = searchdata.replace(id, '');
                  // removing double quotes
                  searchDataAfterRemovingQuotes.push(id.substring(1, id.length - 1))
                })
              }
              searchdata = searchdata.trim();
              searchdata = searchdata.replace(/[ ,]+/g, ",");
              searchdata = searchdata.replace(/;/g, ",");
              searchdata = searchdata.split(',')
              searchdata = searchdata.filter((id) => id != "")
              searchdata = searchdata.map(function (el) {
                return el.trim();
              });
              searchdata = searchdata.join(",");
              this.chips = [];
              if (searchdata || (searchDataAfterRemovingQuotes && searchDataAfterRemovingQuotes.length>0)) {
                if (searchdata) {
                  this.chips = searchdata.split(",");
                }
                // adding ids with quotes
                if (searchDataAfterRemovingQuotes && searchDataAfterRemovingQuotes.length>0) {
                  this.chips.push(...searchDataAfterRemovingQuotes);
                }
                this.newValues = [];
                for (var k = 0; k < this.chips.length; k++) {
                  this.newValues.push({
                    index: k,
                    searchdata: this.chips[k],
                    isChip: false
                  });
                }
              } else {
                this.newValues.length = 0;
              }
              // adding ids with quotes
              if (searchDataAfterRemovingQuotes && searchDataAfterRemovingQuotes.length>0) {
                searchdata = searchdata ? 
                searchdata.concat(",", searchDataAfterRemovingQuotes.join(",")) : 
                searchDataAfterRemovingQuotes.join(",");
              }
            }
            this.showselect = false;
  
            let customerIds;
              customerIds = this.companySelected.map(e => e.id).join(',');
            
            try {
              let audits = await appsheettoolsService.getAudits(
                searchdata,
                customerIds,
                this.fromDate,
                this.toDate,
                type,
                this.tagValue,
                this.vendorName
              );
              if (audits) {
                this.showselect = true;
                this.loading = false;
                this.isLoading = false;
                this.auditList = audits.unit360LiteAsset.map((d, index) => {
                    return {
                      ...d,
                      sno: index + 1,
                      favColor: "grey",
                      loadfav: false,
                      loadFinance: false
                    }
                  }
                );
                this.exceptionsList = audits.exceptions.split(",");
  
                if (this.isFavourite == true) {
                  this.newValues.length = 0;
                } else {
                  for (var i = 0; i < this.newValues.length; i++) {
                    for (var j = 0; j < this.exceptionsList.length; j++) {
                      if (
                        this.newValues[i].searchdata === this.exceptionsList[j]
                      ) {
                        this.newValues[i]["isChip"] = false;
                        j = this.exceptionsList.length;
                        console.log(j);
                      } else {
                        this.newValues[i]["isChip"] = true;
                      }
                    }
                  }
                }
                //Groupby company name
                this.CountArray = [];
                
                let totalData = this.auditList.filter(
                  (thing, i, arr) =>
                    arr.findIndex(t => t.customerName === thing.customerName) ===
                    i
                );
  
                //Main loop to get distinct companies
                for (let i = 0; i < totalData.length; i++) {
                  this.serviceTypeCountArray = [];
                  //Fltering the companies list for companies count
                  let companyList = this.auditList.filter(
                    x => x.customerName == totalData[i].customerName
                  );
                  //Groupby service types
                  let totalServiceList = companyList.filter(
                    (thing, i, arr) =>
                      arr.findIndex(t => t.serviceType === thing.serviceType) ===
                      i
                  );
                  //Inner loop service types
                  totalServiceList = totalServiceList.filter(
                    m => m.serviceType != null
                  );
                  for (let j = 0; j < totalServiceList.length; j++) {
                    //Filtering by each service type for count
                    let EachServiceList = companyList.filter(
                      y => y.serviceType == totalServiceList[j].serviceType
                    );
  
                    let serviceObj = {
                      serviceCount: EachServiceList.length,
                      serviceName: totalServiceList[j].serviceType
                    };
  
                    this.serviceTypeCountArray.push(serviceObj);
                  }
                  let obj = {
                    customerName: totalData[i].customerName,
                    count: companyList.length,
                    installServicesList: this.serviceTypeCountArray
                  };
                  this.CountArray.push(obj);
                }
                this.ExcelResponse = audits.unit360LiteAsset;
              }

            } catch (err) {
              if (err.response.status == 404) {
                this.isAudit = true;
                this.auditList = [];
              } else {
                this.$toast.error("Unable to load the audits data" + err);
                this.isAudit = false;
              }
              this.loading = false;
              this.isLoading = false;
            }
          } else {
            this.$toast.error("Please select atleast one search criteria. ");
          }
        }
      } 
      else {
        this.$toast.error("Please select the customer");
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    exportTableData() {
      this.exportingExcel = true;

      this.GetExcel = [];
      this.startrow = 3;
      this.GetExcel.push(["Audit Tool"]);
      if (this.searchAudit) {
        this.startrow += 1;
        this.GetExcel.push(["Search Text:" + " " + this.searchAudit]);
      }
      if (this.companySelected.length>0) {
        this.startrow += 1;
        this.GetExcel.push([
          "Selected Customer:" + " " + this.companySelected.map(e => e.name).join(',')
        ]);
      }
      if (this.fromDate) {
        this.startrow += 1;
        this.GetExcel.push(["From Date:" + " " + this.fromDate]);
      }
      if (this.toDate) {
        this.startrow += 1;
        this.GetExcel.push(["To Date:" + " " + this.toDate]);
      }
      this.GetExcel.push([
        "Customer Name",
        "Branch Name",
        "TU Serial",
        "Machine Id",
        "Machine Year",
        "Machine Serial",
        "Manufacturer Name",
        "Model",
        'Machine Status',
        "Machine Description",
        "Machine Type",
        "Service Type",
        "Service Date",
        "Vendor Name",
        "Source",
        "Engine Hours",
        "Reference Field",
        "Installation City",
        "Installation State"
      ]);

      let tempExcel = this.ExcelResponse.map(function (item) {
        const {          
          customerName,
          branchName,
          tuSerial,
          machineId,
          machineYear,
          machineSerial,
          manufactureName,
          model,
          machineStatus,
          machineDescription,
          machineType,
          serviceType,
          serviceDate,
          vendorName,
          source,
          engineHours,
          referenceField,
          installationCity,
          installationState
        } = item;
        return {          
          customerName,
          branchName,
          tuSerial,
          machineId,
          machineYear,
          machineSerial,
          manufactureName,
          model,
          machineStatus,
          machineDescription,
          machineType,
          serviceType,
          serviceDate,
          vendorName,
          source,
          engineHours,
          referenceField,
          installationCity,
          installationState
        }
      })
      let datarow = "A" + this.startrow;
      var ws = XLSX.utils.aoa_to_sheet(this.GetExcel);
      XLSX.utils.sheet_add_json(ws, tempExcel, {
        header: [
          "customerName",
          "branchName",
          "tuSerial",
          "machineId",
          "machineYear",
          "machineSerial",
          "manufactureName",
          "model",
          "machineStatus",
          "machineDescription",
          "machineType",
          "serviceType",
          "serviceDate",
          "vendorName",
          "source",
          "engineHours",
          "referenceField",
          "installationCity",
          "installationState"
        ],
        skipHeader: true,
        origin: datarow
      });
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Audit List");
      this.GetExcel = [];
      XLSX.writeFile(wb, "Audit List.xlsx");
      this.exportingExcel = false;
      this.selectedExportOption = null

    },
    exportSummaryToExcel() {
      //parsing the  this.CountArray
      this.exportingExcel = true;
      let excelExportArray = [];
      this.CountArray.map(item => {
        item.installServicesList.map(serviceItem => {
          let row = {
            customerName: item.customerName,
            count: item.count,
            serviceType: serviceItem.serviceName,
            serviceTypeCount: serviceItem.serviceCount
          };
          excelExportArray.push(row);
        });
      });

      this.GetExcel = [];
      this.startrow = 3;
      this.GetExcel.push(["Audit Tool"]);
      if (this.searchAudit) {
        this.startrow += 1;
        this.GetExcel.push(["Search Text:" + " " + this.searchAudit]);
      }
      // if (this.SelectedCustomer) {
      //   this.startrow += 1;
      //   this.GetExcel.push([
      //     "Selected Customer:" + " " + this.SelectedCustomer
      //   ]);
      // }
      // if (this.companyName) {
      //   this.startrow += 1;
      //   this.GetExcel.push([
      //     "Selected Customer:" + " " + this.companyName
      //   ]);
      // }
      if (this.companySelected.length>0) {
        this.startrow += 1;
        this.GetExcel.push([
          "Selected Customer:" + " " + this.companySelected.map(e => e.name).join(',')
        ]);
      }
      if (this.fromDate) {
        this.startrow += 1;
        this.GetExcel.push(["From Date:" + " " + this.fromDate]);
      }
      if (this.toDate) {
        this.startrow += 1;
        this.GetExcel.push(["To Date:" + " " + this.toDate]);
      }

      this.GetExcel.push(["Customer Name", "Count", "Service Type", "Count"]);
      let datarow = "A" + this.startrow;
      var ws = XLSX.utils.aoa_to_sheet(this.GetExcel);
      XLSX.utils.sheet_add_json(ws, excelExportArray, {
        header: ["customerName", "count", "serviceType", "serviceTypeCount"],
        skipHeader: true,
        origin: datarow
      });

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Audit List Summary");
      this.GetExcel = [];
      XLSX.writeFile(wb, "Audit List Summary.xlsx");
      this.exportingExcel = false;
      this.selectedExportOption = null

    },
    exportIconClick() {
      if (this.selectedExportOption) {
        this.exportingExcel = true;
        if (this.selectedExportOption == this.exportOptions[0]) {
          setTimeout(() => {
            this.exportSummaryToExcel();
          }, 3000);
        } else {
          setTimeout(() => {
            this.exportTableData();
          }, 3000);
        }
      } else {
        this.$toast.error("Please select an export option");
      }
    },
    onClickPanel() {
      const curr = this.panel
      this.panel = curr === undefined ? 1 : undefined
    },
    onExpansionPanelClick(event) {
      if(event.currentTarget.classList.contains('v-expansion-panel-header--active')) {
        console.log("Panel is closing/now closed.")
      } else {
        console.log("Panel is opening/now open.")
      }
    }
  }
};
