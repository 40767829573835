import AppWidget from "@/widget/AppWidget.vue";
import { VueEditor } from "vue2-editor";
import apiService from "../../../api/appsheettools.service";
import DeleteDialog from "../../secured/DeleteDialog";
import moment from "moment";
import Vue from "vue";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Dropzone from "../../../views/DropZone.vue";
import utils from "../../../utils/utils";
import rules from "../../../utils/rules";
Vue.use(Viewer);
export default {
  name: "viewaudititem",
  components: { AppWidget, VueEditor, DeleteDialog, Dropzone },
  props: [
    "serviceID",
    "viewfavicon",
    "viewFinanceIcon",
    "auditArrayItem",
    "customerName",
    "auditFinanceFromDate",
    "auditFinanceToDate",
    "selectedServiceType"
  ],
  data() {
    return {
      isfavicon: Boolean,
      totalImages: [],
      assetImageCount: "",
      inspectionImageCount: "",
      emptyImagesCount: "",
      viewAuditDialog: true,
      isDelete: false,
      deleteNote: null,
      addComment: true,
      editor: false,
      comments: [],
      comment: "",
      loader: false,
      rules: rules,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"]
      ],
      auditItem: {},
      otherPicturesList: [],
      loading: false,
      loadingNotes: false,
      loadingServiceDetails: false,
      deleteCommentId: null,
      assetImages: [],
      inspectionImages: [],
      Counter: [],
      InspectionCount: [],
      loadfav: false,
      loadFinance: false,
      userName: "",
      frontCard: false,
      backCard: true,
      valid: true,
      toEmails: [],
      ccEmails: [],
      attachedImages: true,
      items: [],
      items1: [],
      search1: null,
      search: null,
      selectedAssetImages: [],
      selectedInspectionImages: [],
      description: "",
      emailAssetImages: [],
      emailInspectionImages: [],
      files: [],
      // customerName:""
      serviceTypes: "",
      reportType: "AuditTool",
      emailhistoryData: [],
      loadingHistory: false,
      historyImages: [],
      downloadingInspectionReport: false,
      inspectionPanel: [0],
      inspectionReportComment: "",
      selectedInspectionReportImages: [],
      selectedOtherPictureRefImage: null
    };
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        const format = "YYYY-MM-DD";
        return moment(value).format(format);
      }
    },
    formatDateTime: function(value) {
      if (value) {
        var format = 'MM/DD/YYYY hh:mm A';
        return moment(value).format(format);
        // return moment.utc(value).local().format(format);
      }
    },
    formatDateTimeLocal: function (value) {
      if (value) {
        const format = 'MM/DD/YYYY hh:mm A'
        return moment.utc(value)
        .local().format(format)
      }
    },
  },
  computed: {},
  mounted() {
    //this.loadAuditItemData();
    this.getCommentsById();
    this.getServiceById();
    this.getEmailHistory();
    if (this.selectedServiceType != []) {
      this.serviceTypes = this.selectedServiceType.join(",");
    } else {
      this.serviceTypes = "";
    }
    this.userName = this.$store.getters.user;
  },
  watch: {
    toEmails(val, prev) {
      if (val.length === prev.length) return;
      this.toEmails = val.map(v => {
        // v= v.trim();
        // if (typeof v === "string" && v!="") {
        //   v = {
        //     name: v,
        //   };
        // }
        return v;
      });
    },
    ccEmails(val, prev) {
      if (val.length === prev.length) return;
      this.ccEmails = val.map(v => {
        // v=v.trim();
        // if (typeof v === "string" && v!="") {
        //   v = {
        //     name: v,
        //   };

        //   // this.items.push(v);
        // }
        return v;
      });
      // return val;
    }
  },
  methods: {
    dropzoneS(file) {
      utils.fileToBase64(file).then(base64String => {
        let fileData = {
          name: file.name,
          details: base64String.split(",")[1]
        };
        this.files.push(fileData);
      });
    },
    dropzoneR(file) {
      utils.fileToBase64(file).then(base64String => {
        let filterFiles = this.files.filter(function(a) {
          return a.name != file.name && a.details != base64String;
        });
        this.files = filterFiles;
      });
    },
    removeFiles() {
      this.$refs.dropzone.removeAllFiles();
    },
    updateAuditparent() {
      this.$emit("updateAuditparent");
    },
    async OpenFinance() {
      this.loadFinance = true;
      let userId = this.$store.getters.userId;
      let payload = {
        serviceId: this.serviceID,
        type: 2,
        isFlag: !this.auditArrayItem["hasFinance"],
        createdBy: userId
      };
      this.auditArrayItem["hasFinance"] = !this.auditArrayItem["hasFinance"];
      try {
        let finance = await apiService.createFavourite(payload);
        // this.updateAuditparent();
        this.loadFinance = false;
        console.log(finance);
      } catch (error) {
        this.loadFinance = false;
        console.log(error);
      }
    },
    async OpenFavourites() {
      this.loadfav = true;
      let userId = this.$store.getters.userId;
      let payload = {
        serviceId: this.serviceID,
        type: 1,
        isFlag: !this.auditArrayItem["isFavorite"],
        createdBy: userId
      };
      this.auditArrayItem["isFavorite"] = !this.auditArrayItem["isFavorite"];
      try {
        let favourites = await apiService.createFavourite(payload);
        // this.updateAuditparent();
        this.loadfav = false;
        console.log(favourites);
      } catch (error) {
        this.loadfav = false;
        console.log(error);
      }
      // this.loadfav = false;
    },
    // loadAuditItemData() {
    //   this.auditItem = {
    //     assetID: "ABC12334343",
    //     customerName: "Ahern Rentals",
    //     branch: "BON",
    //     tuserial: "123232",
    //     machineID: "43563"
    //   };
    // },
    loadEditor() {
      this.editor = true;
      this.addComment = false;
    },
    removeEditor() {
      this.comment = "";
      this.editor = false;
      this.addComment = true;
    },
    async getServiceById() {
      this.isfavicon = this.auditArrayItem.isFavorite;
      this.loadingServiceDetails = true;
      let userId = this.$store.getters.userId;
      try {
        let apiResult = await apiService.getServiceById(this.serviceID, userId);
        if (apiResult) {
          //this.loadingServiceDetails = false;
          this.auditItem = apiResult;
          //Get Service Other Pictures
          this.getServiceOtherPictures();
          this.assetImages = [];
          this.assetImageCount = "";
          this.assetImageCount = apiResult.assetImages.length;
          this.inspectionImageCount = "";
          this.inspectionImageCount = apiResult.inspectionImages.length;
          apiResult.assetImages.map(imageItem => {
            if (imageItem.url != "") {
              this.assetImages.push(imageItem);
              this.totalImages.push(imageItem);
            }
          });
          for (var i = 0; i < this.assetImages.length; i++) {
            this.emailAssetImages.push({
              id: i + 1,
              src: this.assetImages[i].url
            });
          }
          let assetImagesInitialLength = this.emailAssetImages.length;
          for (var temp = 0; temp < this.inspectionImages.length; temp++) {
            assetImagesInitialLength++;
            this.emailAssetImages.push({
              id: assetImagesInitialLength,
              src: this.inspectionImages[temp].url
            });
          }
          //console.log("emailAssetImages", this.emailAssetImages);

          this.Counter = apiResult.assetImages.filter(e => e.url == "").length;

          apiResult.inspectionImages.map(inspectImage => {
            this.inspectionImages = [];
            if (inspectImage.url != "") {
              this.inspectionImages.push(inspectImage);
              this.totalImages.push(inspectImage);
            }
          });

          for (var j = 0; j < this.inspectionImages.length; j++) {
            this.emailInspectionImages.push({
              id: j + 1,
              src: this.inspectionImages[j].url
            });
          }
          this.InspectionCount = apiResult.inspectionImages.filter(
            e => e.url == ""
          ).length;
          // this.InspectionCount = apiResult.inspectionImages.filter(e => e.url != "").length;
        }
      } catch (err) {
        this.loadingNotes = false;
        this.$toast.error("Unable to load the service" + err);
        this.dialog = false;
      }
    },
    async getServiceOtherPictures() {
      try {
        let apiResult = await apiService.getServiceOtherPictures(
          this.auditItem.id,
          this.auditItem.serviceId
        );
        if (apiResult) {
          this.otherPicturesList = [];
          apiResult.data.map(serviceItem => {
            let tempImages = [];
            serviceItem.images.map((imageItem, index) => {
              if (imageItem.url != null && imageItem.url != "") {
                index++;
                imageItem.src = imageItem.url;
                imageItem.id = index;
                tempImages.push(imageItem);
              }
            });

            //dummy code -start

            // tempImages.push({
            //   url:
            //     "https://www.appsheet.com/template/getappfileurl?appName=AhernRentalsv20-QrewTest-1010123&fileName=/Services_Images/Allied Equipment_000059.Input1ConnectionPhoto.210357.jpg",
            //   src:
            //     "https://www.appsheet.com/template/getappfileurl?appName=AhernRentalsv20-QrewTest-1010123&fileName=/Services_Images/Allied Equipment_000059.Input1ConnectionPhoto.210357.jpg",
            //   name: "abc",
            //   id: 11
            // });

            // tempImages.push({
            //   url:
            //     "https://www.appsheet.com/template/getappfileurl?appName=AhernRentalsv20-QrewTest-1010123&fileName=/Services_Images/Allied Equipment_000059.HRMeterPhoto.210356.jpg",
            //   src:
            //     "https://www.appsheet.com/template/getappfileurl?appName=AhernRentalsv20-QrewTest-1010123&fileName=/Services_Images/Allied Equipment_000059.HRMeterPhoto.210356.jpg",
            //   name: "def",
            //   id: 12
            // });

            // tempImages.push({
            //   url:
            //     "https://www.appsheet.com/template/getappfileurl?appName=AhernRentalsv20-QrewTest-1010123&fileName=/Services_Images/Allied Equipment_000059.InstallationIntegrityPhoto.210355.jpg",
            //   src:
            //     "https://www.appsheet.com/template/getappfileurl?appName=AhernRentalsv20-QrewTest-1010123&fileName=/Services_Images/Allied Equipment_000059.InstallationIntegrityPhoto.210355.jpg",
            //   name: "ghi",
            //   id: 13
            // });

            //dummy code - end

            serviceItem.images = tempImages;
            this.otherPicturesList.push(serviceItem);
          });

          this.loadingServiceDetails = false;
        }
      } catch (err) {
        this.$toast.error("Unable to load the service other pictures" + err);
      }
    },
    async getCommentsById() {
      this.loadingNotes = true;
      try {
        let apiResult = await apiService.getNotes(this.serviceID);
        if (apiResult) {
          apiResult.map(item => {
            item.edit = false;
          });

          this.loadingNotes = false;
          this.comments = apiResult;
        }
      } catch (err) {
        this.loadingNotes = false;
        this.$toast.error("Unable to load the notes" + err);
        this.dialog = false;
      }
    },
    async saveComment() {
      if (this.comment) {
        this.loader = true;

        let payload = {
          serviceId: this.serviceID,
          description: this.comment,
          createdBy: this.$store.getters.name
        };
        try {
          let apiResult = await apiService.addNote(payload);
          if (apiResult.isSuccess == true) {
            this.getCommentsById();
            this.loader = false;
            this.removeEditor();
            this.$toast.success("Note added successfully");
          }
        } catch (err) {
          this.$toast.error("Unable to save the note" + err);
          this.loader = false;
        }
      }
    },
    async updateComment(commentId, comment) {
      if (comment.description) {
        this.loader = true;
        try {
          const payload = {
            serviceId: this.serviceID,
            description: comment.description,
            modifiedBy: this.$store.getters.name
          };
          let apiResult = await apiService.updateNote(commentId, payload);
          if (apiResult.isSuccess == true) {
            this.$toast.success("Note updated successfully");
            this.getCommentsById();
            this.loader = false;
            this.clearEditor(comment);
          }
        } catch (err) {
          this.$toast.error("Unable to update the comment" + err);
          this.loader = false;
        }
      }
    },
    async deleteComment() {
      try {
        let updatedBy = this.$store.getters.name;
        this.isDelete = true;
        let apiResult = await apiService.deleteNote(
          this.deleteCommentId,
          updatedBy
        );
        if (apiResult.isSuccess == true) {
          this.isDelete = false;
          this.$toast.success("Note deleted successfully");
          this.closeConfirmationModal();
          this.getCommentsById();
        }
      } catch (err) {
        this.isDelete = false;
        this.$toast.error("Unable to delete the Note" + err);
      }
    },
    editComment(comment) {
      this._originalComment = Object.assign({}, comment);
      comment.edit = true;
    },
    clearEditor: function(comment) {
      Object.assign(comment, this._originalComment);
      comment.edit = false;
    },
    openDeleteConfirmationModal(id) {
      this.deleteCommentId = id;
      this.$refs.modal.confirmDelete();
    },
    closeDialog() {
      this.viewAuditDialog = false;
    },
    closeConfirmationModal() {
      this.$refs.modal.CloseDialog();
    },
    flipFront() {
      this.frontCard = true;
      this.backCard = false;
    },
    flipBack() {
      this.backCard = true;
      this.frontCard = false;
      this.getEmailHistory();
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    filter1(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    closeEmailDialog() {
      this.ccEmails = [];
      this.toEmails = [];
      this.description = "";
      this.selectedAssetImages.length = 0;
      this.selectedInspectionImages.length = 0;
      this.files = [];
      this.$refs.dropzone.removeAllFiles();
    },
    open(i) {
      window.open(i);
    },
    onSelectMultipleImageA(e) {
      this.selectedAssetImages = e.map(item => {
        return { imageUrl: item.src };
      });
    },
    onSelectMultipleImageI(e) {
      this.selectedInspectionImages = e.map(item => {
        return { imageUrl: item.src };
      });
    },
    onSelectMultipleInspectionImages(e) {
      this.selectedInspectionReportImages.push(e[e.length - 1].src);
    },
    async sendEmail() {
      if (this.$refs.form.validate()) {
        if (this.toEmails.length == 0) {
          this.$toast.warning("To Email is Required");
          return;
        } else if (this.toEmails && this.toEmails.length > 0) {
          this.toEmails.filter(e => {
            if (
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                e
              ) === false
              // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e) === false
            ) {
              this.$toast.warning("Email should be valid email");
              return;
            }
          });
        }
        if (this.ccEmails && this.ccEmails.length > 0) {
          this.ccEmails.filter(e => {
            if (
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                e
              ) === false
              // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e) === false
            ) {
              this.$toast.warning("Email should be valid email");
              return;
            }
          });
        }
        if (this.description == "") {
          this.$toast.warning("Description is required");
          return;
        } else {
          this.loading = true;
          let assetImages = this.selectedAssetImages.map(function(el) {
            return el.imageUrl;
          });
          let inspectionImages = this.selectedInspectionImages.map(function(
            el
          ) {
            return el.imageUrl;
          });
          let FinalImagesList = [];
          FinalImagesList = assetImages.concat(inspectionImages);
          // let toEmails = this.toEmails.map(function(el) {
          //   return el.name;
          // }).join(",");
          // let ccEmails = this.ccEmails.map(function(el) {
          //   return el.name;
          // }).join(",");
          let payload = {
            type: "AuditTool",
            typeValue: this.serviceID,
            toEmails: this.toEmails.join(","),
            ccEmails: this.ccEmails.join(","),
            description: this.description,
            from: this.userName,
            firstName: this.$store.getters.name,
            attachments: this.files,
            imageUrls: FinalImagesList,
            customer: this.customerName,
            serviceType: this.serviceTypes,
            fromDate: this.auditFinanceFromDate,
            toDate: this.auditFinanceToDate
          };
          try {
            let emails = await apiService.sendEmailAuditTool(payload);
            if (emails) {
              this.loading = false;
              this.$refs.form.reset();
              this.closeEmailDialog();
              this.getEmailHistory();
              this.backCard = true;
              this.frontCard = false;
              this.$toast.success("Email has been sent successfully");
            }
          } catch (err) {
            this.closeEmailDialog();
            this.$refs.form.reset();
            this.loading = false;
            this.$toast.error("Unable to send Email" + err);
          }
        }
      }
    },
    async getEmailHistory() {
      this.loadingHistory = true;
      let fromdate = "";
      let todate = "";
      try {
        let emailHistory = await apiService.EmailHistory(
          this.reportType,
          this.serviceID,
          fromdate,
          todate
        );
        if (emailHistory.isSuccess == true) {
          this.loadingHistory = false;
          this.emailhistoryData = emailHistory.notifications;
          for (var k = 0; k < this.emailhistoryData.length; k++) {
            this.historyImages = this.emailhistoryData[k].imageUrls.split(",");
          }
          //  this.$toast.success(emailHistory.systemMessage);
        } else {
          this.loadingHistory = false;
          // this.$toast.warning(emailHistory.systemMessage);
        }
      } catch (err) {
        this.loadingHistory = false;
        this.$toast.warning("Unable to get email history" + err);
      }
    },
    async downloadFile(itemId, fileId) {
      try {
        let apiResult = await apiService.getEmailAttachments(itemId, fileId);
        if (apiResult) {
          console.log(apiResult);
          utils.downloadFile(apiResult);
        }
      } catch (err) {
        this.$toast.error("Unable to load the attachments" + err);
      }
    },
    async downloadInspectionReport() {
      // console.log(
      //   "selected Inspection Images: ",
      //   this.selectedInspectionReportImages
      // );
      let payload = {
        assetId: this.auditItem.id,
        serviceId: this.auditItem.serviceId,
        description: this.inspectionReportComment,
        images: this.selectedInspectionReportImages
      };

      this.downloadingInspectionReport = true;
      try {
        let apiResult = await apiService.getInspectionReport(payload);
        if (apiResult) {
          const url = window.URL.createObjectURL(new Blob([apiResult]), {
            type: "application/pdf"
          });
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Inspection Report.pdf");
          document.body.appendChild(link);
          link.click();
          this.downloadingInspectionReport = false;
        }
      } catch (err) {
        this.downloadingInspectionReport = false;
        this.$toast.error("Unable to load the inspection report" + err);
      }
    },
    cancelInspectionReport() {
      this.inspectionPanel = [0];
      this.$refs.auditServicePics.resetMultipleSelection();
      this.$refs.auditServiceOtherPics.resetMultipleSelection();
      this.selectedInspectionReportImages = [];
      this.inspectionReportComment = "";
    }
  }
};
