<template>
  <v-card>
    <v-app-bar
      v-if="!headerHide"
      flat
      dense
      color="transparent"
    >
     <v-spacer />
      <v-toolbar-title class="headline">
             <v-icon v-if="title == 'Activity'" class="app-primary-color mr-2">mdi-file-document-edit-outline</v-icon>   {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <!-- <slot name="widget-header-action">
        <v-btn icon>
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </slot> -->
    </v-app-bar>
     <slot name="widget-header-action">
                </slot>
    <v-divider v-if="!headerHide" />

    <v-card-text v-if="!paddingHide">
      <slot name="widget-content" />
    </v-card-text>
    <slot
      v-else
      name="widget-content"
    />
  </v-card>
</template>
<style scoped>
.v-toolbar__title.headline {
  /* padding-left: 10px !important; */
  /* text-align: center; */
  margin-bottom:0px;
  font-size: 16px !important;
    font-family: "Segoe UI", "Roboto", sans-serif;
}
.app-primary-color{
  color:#0065e5 !important;
}
</style>
<script>
export default {
  name: 'AppWidget',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'mdi-dots-vertical',
    },
    headerHide: Boolean,
    paddingHide: Boolean,
  },
  data: () => ({}),
};
</script>
